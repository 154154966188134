.page{
    width: calc(772px + 40px);
    padding: 20px;
    background-color: #ffffff;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 32px;
    height: calc(999px + 40px);
    border-radius: 35px 0px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 700px)
{
    .page{
        margin-left: 0px;
        transform: translateX(0%);
    }
}